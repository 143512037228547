import React, { useCallback, useEffect, useState } from 'react';
import PartnerCertificate from './PartnerCertificate';
import config from '../../core/config/config';

type CertificateSheetProps = {
  visible: boolean
  code: string | null,
  id: string | null,
  isForever: string | null,
  partner: string | null,
}

const CertificateSheet = ({ code, id, isForever, partner }: CertificateSheetProps) => {
  // console.log('visible', visible)
  const [visible, setVisible] = useState(false)
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  const copyCodeToClipboard = useCallback(() => {
    if (copiedToClipboard) {
      return setCopiedToClipboard(false)
    }
    navigator.clipboard.writeText(code || '')
    setCopiedToClipboard(true)
  }, [code, copiedToClipboard])

  const downloadPdf = useCallback(() => {
    if (id === null) return alert('Сертификат не валидный')
    const PDF_DOWNLOAD_URL = `${config.apiUrl}/promocodes`;
    window.location.href = `${PDF_DOWNLOAD_URL}/${id}/certificate?text=${code}`
  }, [code, id])

  useEffect(() => {
    setVisible(true)
  })

  return (
    <>
      <div className='hideOnMobile' style={{
        position: 'absolute',
        top: 0,
        opacity: visible ? 1 : 0,
        zIndex: 1000,
        transition: 'opacity ease 500ms',
        alignSelf: "center",
        width: '100%',
        flexDirection: 'column',
        backgroundColor: '#fff',
        margin: '0 auto',
        textAlign: 'center',
        paddingBottom: 32,
      }}>
        <div style={{ padding: 30 }}>
          <img src="https://icons.prostoapp.com/podarok/pink/icon.png" width="60" height="60" />
        </div>
        <div style={{ zIndex: 2, width: 886, alignSelf: 'center' }}>
          <img
            src="images/pink/certificate-top-banner.png"
            width="886"
            height="340"
          />
          {isForever === null ?
            <></> :
            <p style={{
              position: "absolute",
              marginLeft: 550,
              marginTop: -150,
              width: 245,
              fontSize: '18px',
              lineHeight: '28px',
              textAlign: 'center',
              color: '#EB7D8F'
            }}>
              Поздравляем с покупкой
              доступа Prosto {isForever === 'true' ? 'Навсегда ' : 'на год '} <br />
              со скидкой 72%!
            </p>
          }
        </div>

        <div style={{
          marginTop: -170,
          alignSelf: "center",
          backgroundColor: '#FEE8EB',
          width: 1104,
          borderRadius: 16,
          overflow: 'hidden',
          paddingTop: 190,
        }}>
          <p className="TitleText"
            style={{
              margin: '0 auto',
              padding: 0,
            }}
          >
            Подарочный сертификат
          </p>
          <div style={{
            width: 414,
            height: 56,
            margin: '30px auto 10px auto',
            background: copiedToClipboard ? "#EB7D8F" : "#fff",
            borderRadius: 12,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          }}
            onClick={copyCodeToClipboard}
          >
            {copiedToClipboard ?
              <p style={{
                margin: '0 auto',
                padding: 0,
                color: "#fff",
                fontWeight: 'bold'
              }}
              >
                {'Скопировано'}
              </p> :
              <>
                <p className="TitleText"
                  style={{
                    margin: '0 auto',
                    padding: 0,
                  }}
                >
                  {code || ''}
                </p>
                <img
                  src="images/pink/copy.png"
                  width="24"
                  height="24"
                  style={{ marginRight: 16 }}
                />
              </>
            }
          </div>
          <a style={{ textDecoration: 'none', color: '#000' }} href='https//code.prostoapp.ru'>
            Активируйте на сайте <b>code.prostoapp.ru</b>
          </a>
          <div style={{
            width: 196,
            height: 30,
            margin: '40px auto',
            borderRadius: 8,
            border: '1px solid #000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
            onClick={downloadPdf}
          >
            <img
              src="images/pink/print.png"
              width="16"
              height="16"
            />
            <p style={{ marginLeft: 8 }}>
              {'Распечатать'}
            </p>
          </div>
          <div style={{
            position: 'absolute',
            marginTop: -288,
          }}>
            <img
              src="images/pink/YuraYulia.png"
              width="306"
              height="288"
            />
          </div>
        </div>

        <p style={{
          fontFamily: 'Gilroy Semibold',
          fontSize: 32,
        }}>
          Как пользоваться?
        </p>
        <div style={{
          display: "flex",
          flexDirection: 'row',
          justifyContent: 'center'
        }}>
          <div style={{
            display: "flex",
            flexDirection: 'column',
            alignContent: 'flex-start',
            paddingRight: 80
          }}>
            <div style={{
              display: "flex",
              flexDirection: 'row',
              marginTop: 20,
            }}>
              <img
                src={"images/pink/ticket.png"}
                width={50}
                height={35}
                style={{
                  marginTop: 30,
                  paddingRight: 30
                }}
              />
              <p className="CertificateText" style={{ textAlign: 'left', }}>
                Зайдите на сайт <br />
                <a style={{ color: '#EB7D8F' }}
                  href='https://code.prostoapp.ru'> code.prostoapp.ru </a><br />
                и активируйте промокод
              </p>
            </div>

            <div style={{
              display: "flex",
              flexDirection: 'row',
            }}>
              <img
                src={"images/pink/letter.png"}
                width={40}
                height={48}
                style={{
                  marginTop: 30,
                  paddingRight: 30
                }}
              />
              <p className="CertificateText" style={{ textAlign: 'left', }}>
                Выберите номер телефона или адрес<br />
                почты, который будете использовать<br />
                для входа в приложение
              </p>

            </div>

            <div style={{
              display: "flex",
              flexDirection: 'row',
              // marginTop: 20,
            }}>
              <img
                src={"images/pink/logo.png"}
                width={40}
                height={53}
                style={{
                  marginTop: 30,
                  paddingRight: 30
                }}
              />
              <p className="CertificateText" style={{ textAlign: 'left', }}>
                Если у вас есть аккаунт Prosto,<br />
                проверьте логин в профиле — это <br />
                номер/почта, или если Apple ID/Google,<br />
                то добавьте адрес почты и используйте <br />
                его для активации
              </p>
            </div>

            <div style={{
              display: "flex",
              flexDirection: 'row',
            }}>
              <img
                src={"images/pink/phone.png"}
                width={35}
                height={61}
                style={{
                  marginTop: 30,
                  paddingRight: 30
                }}
              />
              <p className="CertificateText" style={{ textAlign: 'left', }}>
                После активации промокода войдите <br />
                в приложение под указанным номером<br />
                телефона или почтой.
                <span style={{ color: '#EB7D8F', marginLeft: 5 }}>
                  Если у вас была <br />
                  активная подписка — отмените ее
                </span>
              </p>

            </div>

          </div>
          <div style={{
            display: "flex",
            flexDirection: 'column',
          }}>
            <img
              src={isForever === 'true' ? "images/pink/present-banner.png" : "images/pink/present-banner-year.png"}
              width="413"
              height="456"
            />
          </div>
        </div>

        <PartnerCertificate partner={partner} />

        <div style={{
          display: "flex",
          flexDirection: 'column',
          width: 170,
          alignSelf: 'center',
          alignItems: "center",
          marginTop: 40,
          marginBottom: 20
        }}>
          <img width={50} height={50} src={"images/app-icon.png"} />
          <p className="FooterText" style={{ color: '#000', fontSize: 12 }}>
            Служба заботы<br />
            С 09:00 до 21:00<br /><br />
            <a href="mailto: help@prostoapp.com" className="FooterMail" style={{ textDecoration: 'none' }}>
              help@prostoapp.com
            </a>
            <br /><br />
          </p>
          <div style={{ display: 'flex' }}>
            <a className="FooterLink" href="https://apps.apple.com/ru/app/просто-медитация-и-сон/id1490891826?mt=8">
              Appstore
            </a>
            <a className="FooterLink" href='https://play.google.com/store/apps/details?id=com.prostoapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              GooglePlay
            </a>
          </div>
        </div>

      </div >

      <div className='hideOnDesktop' style={{
        position: 'absolute',
        top: 0,
        opacity: visible ? 1 : 0,
        zIndex: 1000,
        transition: 'opacity ease 500ms',
        alignSelf: "center",
        width: '100%',
        flexDirection: 'column',
        backgroundColor: '#fff',
        margin: '0 auto',
        textAlign: 'center',
        paddingBottom: 32,
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: "center",
          padding: 30
        }}>
          <img src="https://icons.prostoapp.com/podarok/pink/icon.png" width="60" height="60" />
          <p style={{
            padding: 0,
            margin: 0,
            marginTop: 10,
            fontSize: 14,
            color: 'rgba(0,0,0,0.4)'
          }}>
            Prosto — Nº1 приложение<br />
            для медитации и сна в России
          </p>
        </div>
        <div style={{ zIndex: 2, width: 343, alignSelf: 'center' }}>
          <img
            src="images/pink/certificate-top-banner-mobile.png"
            width="343"
            height="533"
          />
          {isForever === null ?
            <></> :
            <p style={{
              position: "absolute",
              marginLeft: 90,
              marginTop: -436,
              width: 210,
              fontSize: '16px',
              lineHeight: '25px',
              textAlign: 'center',
              color: '#EB7D8F'
            }}>
              Поздравляем с покупкой
              доступа Prosto {isForever === 'true' ? 'Навсегда ' : 'на год '} <br />
              со скидкой 72%!
            </p>
          }
        </div>

        <div style={{
          marginTop: -170,
          alignSelf: "center",
          backgroundColor: '#FEE8EB',
          width: '100%',
          borderRadius: 16,
          overflow: 'hidden',
          paddingTop: 190,
        }}>
          <p className="TitleText"
            style={{
              margin: '0 auto',
              padding: 0,
              fontSize: 30
            }}
          >
            Подарочный<br /> сертификат
          </p>
          <div style={{
            width: 300,
            height: 56,
            margin: '20px auto 20px auto',
            background: copiedToClipboard ? "#EB7D8F" : "#fff",
            borderRadius: 12,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          }}
            onClick={copyCodeToClipboard}
          >
            {copiedToClipboard ?
              <p style={{
                margin: '0 auto',
                padding: 0,
                color: "#fff",
                fontWeight: 'bold'
              }}
              >
                {'Скопировано'}
              </p> :
              <div style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'space-between',
                alignItems: "center"
              }}>
                <p className="TitleText"
                  style={{
                    margin: 0,
                    padding: 0,
                    paddingLeft: 50,
                  }}
                >
                  {code || ''}
                </p>
                <img
                  src="images/pink/copy.png"
                  width="24"
                  height="24"
                  style={{ marginRight: 16 }}
                />
              </div>
            }
          </div>
          <a style={{ textDecoration: 'none', color: '#000' }} href='https//code.prostoapp.ru'>
            Активируйте на сайте <br />
            <b>code.prostoapp.ru</b>
          </a>
          <div style={{
            width: 196,
            height: 30,
            margin: '40px auto',
            borderRadius: 8,
            border: '1px solid #000',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer'
          }}
            onClick={downloadPdf}
          >
            <img
              src="images/pink/print.png"
              width="16"
              height="16"
            />
            <p style={{ marginLeft: 8 }}>
              {'Распечатать'}
            </p>
          </div>
          <img
            src="images/pink/YuraYulia-mobile.png"
            width="376"
            height="241"
          />
        </div>

        <p style={{
          fontFamily: 'Gilroy Semibold',
          fontSize: 26,
        }}>
          Как пользоваться?
        </p>
        <div style={{
          display: "flex",
          flexDirection: 'column',
        }}>
          <div style={{
            display: "flex",
            flexDirection: 'column',
          }}>
            <div style={{
              display: "flex",
              flexDirection: 'column',
              alignItems: 'center'
            }}>
              <img
                src={"images/pink/ticket.png"}
                width={50}
                height={35}
              />
              <p className="CertificateText">
                Зайдите на сайт <br />
                <a style={{ color: '#EB7D8F' }}
                  href='https://code.prostoapp.ru'> code.prostoapp.ru </a><br />
                и активируйте<br />
                промокод
              </p>
            </div>

            <div style={{
              display: "flex",
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 20,
            }}>
              <img
                src={"images/pink/letter.png"}
                width={40}
                height={48}
              />
              <p className="CertificateText">
                Выберите номер телефона<br />
                или адрес почты, который <br />
                будете использовать<br />
                для входа в приложение
              </p>
            </div>

            <div style={{
              display: "flex",
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 20,
            }}>
              <img
                src={"images/pink/logo.png"}
                width={40}
                height={53}
              />
              <p className="CertificateText">
                Если у вас есть аккаунт Prosto,<br />
                проверьте логин в профиле — <br />
                это номер/почта, или если Apple <br />
                ID/Google, то добавьте адрес почты<br />
                и используйте  его для активации
              </p>
            </div>

            <div style={{
              display: "flex",
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 20,
            }}>
              <img
                src={"images/pink/phone.png"}
                width={35}
                height={61}
              />
              <p className="CertificateText">
                После активации промокода <br />
                войдите в приложение под <br />
                указанным номером <br />
                телефона или почтой.<br />
                <span style={{ color: '#EB7D8F' }}>
                  Если у вас была активная <br />подписка — отмените ее
                </span>
              </p>

            </div>

          </div>
          <div style={{
            display: "flex",
            flexDirection: 'column',
            alignSelf: 'center',
            marginTop: 30
          }}>
            <img
              src={isForever === 'true' ? "images/pink/present-banner.png" : "images/pink/present-banner-year.png"}
              width="343"
              height="384"
            />
          </div>
        </div>

        <PartnerCertificate partner={partner} />

        <div style={{
          display: "flex",
          flexDirection: 'column',
          width: 170,
          alignSelf: 'center',
          alignItems: "center",
          marginTop: 40,
          marginBottom: 20
        }}>
          <img width={50} height={50} src={"images/app-icon.png"} />
          <p className="FooterText" style={{ color: '#000', fontSize: 12 }}>
            Служба заботы<br />
            С 09:00 до 21:00<br /><br />
            <a href="mailto: help@prostoapp.com" className="FooterMail" style={{ textDecoration: 'none' }}>
              help@prostoapp.com
            </a>
            <br /><br />
          </p>
          <div style={{ display: 'flex' }}>
            <a className="FooterLink" href="https://apps.apple.com/ru/app/просто-медитация-и-сон/id1490891826?mt=8">
              Appstore
            </a>
            <a className="FooterLink" href='https://play.google.com/store/apps/details?id=com.prostoapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
              GooglePlay
            </a>
          </div>
        </div>

      </div >

    </>
  );
}

export default CertificateSheet;
